import React, { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { FilesApi } from "soundboard-api";
import "./App.scss";
import { LoginButton } from "./components/auth/LoginButton";
import { Layout } from "./components/Layout";
import { PageLoader } from "./components/loaders/PageLoader";
import { useApi } from "./utils/useApi";
import { useAuthentication } from "./utils/useAuthentication";
import { SignInSplash } from "./components/sign-in-splash/SignInSplash";

function App() {
  const { isLoading, userData, userManager, isAuthenticated } =
    useAuthentication();
  if (!isAuthenticated) return <SignInSplash />;

  return <div className="App">{isLoading ? <PageLoader /> : <Layout />}</div>;
}

export default App;
