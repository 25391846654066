import { List, ListItem } from "@mui/material";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { queueAtom } from "../../atoms/QueueAtom";
import { QueueItem } from "./QueueItem";
import "./Queue.scss";

interface Props {}

export const Queue = (props: Props) => {
  const [queue, setQueue] = useRecoilState(queueAtom);
  const [displayHistoric, setDisplayHistoric] = useState(false);

  const handleQueueChange = (queueKey: string) => {
    const queueItem = [...queue.history, ...queue.items].find(
      (x) => x.queueKey === queueKey
    );
    if (queueItem) {
      const isHistoric = queue.history.includes(queueItem);
      if (isHistoric) {
        const index = queue.history.indexOf(queueItem);
        const history = queue.history.slice(index + 1);
        const newItems = queue.history.slice(0, index + 1).reverse();
        const items = [...newItems, ...queue.items];
        setQueue({ history, items });
      } else {
        const index = queue.items.indexOf(queueItem);
        const newHistory = queue.items.slice(0, index).reverse();
        const history = [...newHistory, ...queue.history];
        const items = queue.items.slice(index);
        setQueue({ history, items });
      }
    }
  };

  return (
    <div className="queue">
      <header>
        <h1>Queue</h1>
      </header>
      <List>
        {queue.history.length > 0 && (
          <div className={`historic ${displayHistoric ? "active" : ""}`}>
            <div
              className="display-btn"
              onClick={() => setDisplayHistoric(!displayHistoric)}
            >
              <div>{displayHistoric ? "Hide History" : "Show History"}</div>
            </div>
            <div className="content">
              {[...queue.history].reverse().map((x, i) => {
                return (
                  <QueueItem
                    key={x.queueKey}
                    queueItem={x}
                    className="queue-item history-item"
                    onClick={(e) => handleQueueChange(x.queueKey)}
                  />
                );
              })}
            </div>
          </div>
        )}
        <div className="queued">
          {queue.items.map((x, i) => {
            return (
              <QueueItem
                key={x.queueKey}
                queueItem={x}
                className={`queue-item future-item ${
                  i === 0 ? "active" : ""
                }`.trim()}
                onClick={(e) => handleQueueChange(x.queueKey)}
              />
            );
          })}
        </div>
      </List>
      {queue.items.length === 0 && !displayHistoric && (
        <div className="empty">
          <h1>Nothing to see here</h1>
          <h2>Play or Queue something to get started.</h2>
        </div>
      )}
    </div>
  );
};
