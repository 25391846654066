import React, { createRef, useEffect, useState } from "react";
import { AudioFile } from "soundboard-api";
import { AudioPlayer, useMediaControls } from "react-flat-player";
import { Chip, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./Sound.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthentication } from "../../utils/useAuthentication";
import Tooltip from "@mui/material/Tooltip";

interface Props {
  file: AudioFile;
}

export const Sound = (props: Props) => {
  const navigate = useNavigate();
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const { userData } = useAuthentication();
  const userId = userData?.profile.sub;
  const audioRef = createRef<HTMLAudioElement>();

  return (
    <div className="sound" id={props.file.id}>
      <div className="left">
        <header className="header">
          <Tooltip title={props.file.name} placement="top">
            <h1>{props.file.name}</h1>
          </Tooltip>
          <h2>by {props.file.author.name}</h2>
        </header>
        <Chip label={props.file.extension} />
        <div className="options">
          {props.file.authorId === userId && (
            <>
              <IconButton onClick={() => navigate(`Edit/${props.file.id}`)}>
                <Tooltip placement="top" title="Edit">
                  <EditIcon />
                </Tooltip>
              </IconButton>
              <IconButton onClick={() => navigate(`Delete/${props.file.id}`)}>
                <Tooltip placement="top" title="Delete">
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
            </>
          )}
        </div>
      </div>
      <div className="right">
        {props.file.description && (
          <IconButton>
            <Tooltip
              placement="top"
              title={!descriptionOpen ? "Show Description" : "Hide Description"}
            >
              <KeyboardArrowDownIcon
                className={`expand-icon ${
                  descriptionOpen ? "open" : ""
                }`.trim()}
                onClick={() => {
                  setDescriptionOpen(!descriptionOpen);
                }}
              />
            </Tooltip>
          </IconButton>
        )}
      </div>
      <div className={`description ${descriptionOpen ? "open" : ""}`.trim()}>
        {props.file.description && <div>{props.file.description}</div>}
      </div>
      <div className="content">
        <AudioPlayer
          audioRef={audioRef}
          src={`/api/files/stream/${props.file.id}`}
        />
      </div>
    </div>
  );
};
