import React from "react";
import { SearchList } from "../components/search/SearchList";
import { MediaMenu } from "../components/media-menu/MediaMenu";

interface Props {}

export const SearchPage = (props: Props) => {
  return (
    <section className="page">
      <div style={{ display: "flex", gap: "24px" }}>
        <MediaMenu />
        <SearchList />
      </div>
    </section>
  );
};
