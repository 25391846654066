import React from "react";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import "./SignInSplash.scss";
import { Paper } from "@mui/material";
import { useAuthentication } from "../../utils/useAuthentication";

interface Props {}

export const SignInSplash = (props: Props) => {
  const { isAuthenticated, isLoading, signInWithRedirect, signOut, userData } =
    useAuthentication();

  return (
    <div className="sign-in-splash">
      <div className="content">
        <Paper elevation={3} className="paper">
          <header>
            <h1>Better Soundboard</h1>
            <h2>You must sign in to continue</h2>
          </header>
          <div className="actions">
            <button className="login-btn" onClick={signInWithRedirect}>
              <span>
                Sign in with SSO <LockOpenIcon />
              </span>
            </button>
          </div>
        </Paper>
      </div>
    </div>
  );
};
