import React from "react";
import { LoginButton } from "../components/auth/LoginButton";
import { useAuthentication } from "../utils/useAuthentication";

interface Props {}

export const DebugPage = (props: Props) => {
  const { isLoading, userData, profile } = useAuthentication();

  return (
    <section className="page">
      <header className="App-header">
        <LoginButton />
        <p>Loading: {isLoading ? "true" : "false"}</p>
        <p>Token: {userData?.access_token}</p>
        <pre>
          {JSON.stringify(userData)
            .replaceAll(/[{]/g, "{\n")
            .replaceAll(/,/g, ",\n")}
        </pre>
      </header>

      <p>Roles: {JSON.stringify(profile?.roles)}</p>
    </section>
  );
};
