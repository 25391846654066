import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchList.scss";
import { AudioFileSolrDocument } from "soundboard-api";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import { useRecoilState } from "recoil";
import { queueAtom } from "../../atoms/QueueAtom";
import { v4 as uuidv4 } from "uuid";

interface Props {
  selection?: AudioFileSolrDocument;
}

export const SearchListSelection = (props: Props) => {
  const [queue, setQueue] = useRecoilState(queueAtom);

  const addToQueue = (sound: AudioFileSolrDocument) => {
    const items = [...queue.items];
    items.push({ id: sound.audioFileId, sound: sound, queueKey: uuidv4() });
    console.log(sound.audioFileName);
    setQueue({ ...queue, items: items });
  };

  if (props.selection) {
    const selection = props.selection;
    return (
      <div className="search-list-selection">
        <div className="thumbnail-container">
          <QuestionMarkIcon className="no-thumbnail" />
        </div>
        <div className="content">
          <h1>{props.selection.audioFileName}</h1>
          <h2>By {props.selection.audioFileAuthorNames.join(", ")}</h2>
          <div className="description"></div>
          <div className="actions">
            <div className="play btn">
              <PlayCircleIcon />
            </div>
            <div className="queue btn" onClick={() => addToQueue(selection)}>
              <QueueOutlinedIcon />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="search-list-selection">
      <div className="thumbnail-container">
        <SearchIcon className="placeholder" />
      </div>
      <div className="content placeholder">
        <h1>Search for a sound</h1>
      </div>
    </div>
  );
};
