import React, { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { SoundGrid } from "../components/sounds/SoundGrid";
import { SoundSearch } from "../components/sounds/SoundSearch";

interface Props {}

export const SoundsPage = (props: Props) => {
  return (
    <section className="page">
      <React.Suspense fallback={<div>Loading...</div>}>
        <div>
          <SoundSearch />
        </div>
      </React.Suspense>
      <React.Suspense fallback={<div>Loading...</div>}>
        <SoundGrid />
      </React.Suspense>
      <React.Suspense>
        <Outlet />
      </React.Suspense>
    </section>
  );
};
