import { FormControl, Input, InputAdornment } from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import "./SearchInput.scss";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useRecoilState } from "recoil";
import { searchQueryAtom } from "../../atoms/SearchSelector";
import { useDebounce } from "../../utils/useDebounce";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

interface Props {}

export const SearchInput = (props: Props) => {
  const [focused, setFocused] = useState(false);
  const [hover, setHover] = useState(false);
  const active = focused || hover;
  const [query, setQuery] = useRecoilState(searchQueryAtom);
  const inputRef = createRef<HTMLInputElement>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState(searchParams.get("search"));
  const navigate = useNavigate();
  const location = useLocation();

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => setFocused(true);

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement> | undefined
  ) => setFocused(false);

  const handleHover = (e: React.MouseEvent<HTMLDivElement | Element>) =>
    setHover(true);
  const handleHoverLeave = (e: React.MouseEvent<HTMLDivElement | Element>) =>
    setHover(false);

  const debouncedSetQuery = useDebounce(setQuery, 200);
  const handleInputChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (query.query && !location.pathname.startsWith("/media/search")) {
      navigate("/media/search");
    }
    setInputValue(e.currentTarget.value);
    debouncedSetQuery({ ...query, query: e.currentTarget.value });
  };
  const handleClear = () => {
    setQuery({ ...query, query: "" });
    setInputValue("");
    setSearchParams((prev) => {
      prev.delete("search");
      return prev;
    });
  };
  const renderClear = query.query.length > 0;
  return (
    <div
      className={`search-input-container ${active ? "active" : ""} ${
        focused ? "focused" : ""
      }`.trim()}
      onMouseOver={handleHover}
      onMouseOut={handleHoverLeave}
      onClick={(e) => {
        inputRef.current?.focus();
      }}
    >
      <FormControl variant="standard">
        <Input
          disableUnderline
          inputRef={inputRef}
          value={inputValue}
          onChange={handleInputChanged}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`search-input`}
          startAdornment={
            <InputAdornment position="start" className="search-icon">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            renderClear && (
              <InputAdornment
                position="end"
                className="clear-icon"
                onClick={handleClear}
              >
                <ClearIcon />
              </InputAdornment>
            )
          }
        />
      </FormControl>
    </div>
  );
};
