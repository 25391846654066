import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { IQueueItem } from "../../atoms/QueueAtom";
import "./Queue.scss";
import { formatTime } from "../../utils/formatTime";

interface Props {
  queueItem: IQueueItem;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

export const QueueItem = ({ queueItem, className, onClick }: Props) => {
  return (
    <ListItem
      disablePadding
      className={className}
      onClick={onClick}
      secondaryAction={
        <span className="duration">
          {formatTime(queueItem.sound.audioFileDuration)}
        </span>
      }
    >
      {/* <ListItemButton> */}
      <ListItemText
        primary={queueItem.sound.audioFileName}
        secondary={queueItem.sound.audioFileAuthorNames.join(", ")}
      />
      {/* </ListItemButton> */}
    </ListItem>
  );
};
