import { Skeleton, TableCell, TableRow } from "@mui/material";
import React, { Suspense, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { searchSelector } from "../../atoms/SearchSelector";
import { useAuthentication } from "../../utils/useAuthentication";
import { SearchListItem } from "./SearchListItem";
import "./SearchList.scss";
import { AudioFileSolrDocument, SearchResultDto } from "soundboard-api";

interface Props {
  onSelection?: (sound: AudioFileSolrDocument) => void;
  onSearchChange?: (search: SearchResultDto | null) => void;
  selectedItem?: AudioFileSolrDocument;
}

export const SearchListBody = (props: Props) => {
  const { token } = useAuthentication();
  const search = useRecoilValue(searchSelector(token));
  const documents = search?.documents ?? [];

  useEffect(() => {
    props.onSearchChange && props.onSearchChange(search);
  }, [props, search]);

  return (
    <>
      {documents.map((sound) => (
        <SearchListItem
          key={sound.audioFileId}
          active={props.selectedItem?.audioFileId === sound.audioFileId}
          onClick={(sound) => props.onSelection && props.onSelection(sound)}
          sound={sound}
        />
      ))}
    </>
  );
};

export const SearchListBodyFallback = (props: { count: number }) => {
  const arr = new Array(props.count).fill("0").map((x, i) => {
    return (
      <TableRow key={i}>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
    );
  });
  return <>{arr}</>;
};
