import { CircularProgress } from "@mui/material";
import React from "react";

type Props = {};

export const PageLoader = (props: Props) => {
  return (
    <div className="page-loader flex center">
      <CircularProgress className="loader" />
    </div>
  );
};
