import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useApi } from "../../utils/useApi";
import { useAuthentication } from "../../utils/useAuthentication";
import { FilesApi } from "soundboard-api";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue
} from "recoil";
import { soundFilesSelector } from "../../atoms/SoundFileSelector";
import {
  Button,
  Chip,
  IconButton,
  Input,
  TextField,
  Tooltip
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { mimeTypeSelector } from "../../atoms/MimeTypeSelector";
import "./AddSoundsForm.scss";
import { errorAtom } from "../../atoms/ErrorAtom";
import { CenterLoader } from "../loaders/CenterLoader";
import DoneIcon from "@mui/icons-material/Done";

interface Props {
  onSubmit?: () => void;
  onClose: () => void;
}

interface IFile {
  blobs?: FileList;
}

interface IAddSoundsForm {
  files: IFile[];
}

export const AddSoundsForm = (props: Props) => {
  const { token } = useAuthentication();
  const fileApi = useApi(FilesApi, token);
  const allowedMimeTypes = useRecoilValue(mimeTypeSelector(token));
  const refresh = useRecoilRefresher_UNSTABLE(soundFilesSelector(token));
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const { register, handleSubmit, reset } = useForm<IAddSoundsForm>({
    reValidateMode: "onBlur",
    defaultValues: {
      files: [{ blobs: undefined }]
    }
  });
  const [error, setError] = useRecoilState(errorAtom);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const acceptStr = allowedMimeTypes.join(", ");

  const handleFormSubmitted = async (data: IAddSoundsForm) => {
    setLoading(true);
    setSuccess(false);
    try {
      const blobs = filesToUpload;
      if (blobs.every((x) => !hasError(x))) {
        await fileApi.apiFilesPost({ files: blobs });
        refresh();
        props.onSubmit && props.onSubmit();
        reset();
        setFilesToUpload([]);
        setSuccess(true);
      }
    } catch (e) {
      setLoading(false);
      setError((e as string).toString());
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      const fileArr = new Array<File>();
      for (let i = 0; i < e.currentTarget.files.length; i++) {
        const file = e.currentTarget.files.item(i);
        if (file) fileArr.push(file);
      }
      setFilesToUpload([...filesToUpload, ...fileArr]);
    }
    e.currentTarget.value = "";
  };

  const hasError = (file: File) => {
    if (!(file.size > 0)) return "File must not be empty";
    if (!allowedMimeTypes.includes(file.type))
      return `Mimetype "${file.type}" not allowed`;
    return undefined;
  };

  return (
    <div className="add-sounds-form">
      <h1>Add one or more sounds</h1>
      <p>Drag sounds to the input below or click the button to upload files.</p>
      <div>
        Only the following mimetypes are accepted:
        <div className="mimetypes">
          {allowedMimeTypes.map((x) => (
            <Chip key={x} className="mimetype" label={x} />
          ))}
        </div>
      </div>
      <form
        onSubmit={handleSubmit((data) => handleFormSubmitted(data))}
        className="form"
      >
        {filesToUpload.map((x, i) => {
          return (
            <div
              className={`input added-file ${
                hasError(x) ? "error" : ""
              }`.trim()}
              key={i}
            >
              <Tooltip
                disableFocusListener={!hasError(x)}
                disableHoverListener={!hasError(x)}
                disableInteractive={!hasError(x)}
                disableTouchListener={!hasError(x)}
                placement="top"
                followCursor
                title={hasError(x)}
              >
                <div className="file-input">
                  <Chip className="chip" label={x.type} />
                  {x.name}
                </div>
              </Tooltip>
              <IconButton
                onClick={() => {
                  const newFiles = [...filesToUpload].filter((y) => y !== x);
                  return setFilesToUpload(newFiles);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        })}
        <div className="input">
          <TextField
            type="file"
            className="file-input"
            inputProps={{ multiple: true, accept: acceptStr }}
            {...register(`files.0.blobs` as const, {
              onChange: (e) => handleInputChange(e)
            })}
          />
          <div className="btn-spacer" />
        </div>
        <div className="input">
          <div className="file-input">
            <Button type="submit">Submit</Button>
            <Button type="button" onClick={() => props.onClose()}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
      {loading && (
        <div className="progress-spinner">
          <div className={"loader" + (success ? " hidden" : "")}>
            <CenterLoader />
          </div>
          <div className={"success" + (success ? " active" : "")}>
            <DoneIcon />
          </div>
        </div>
      )}
    </div>
  );
};
