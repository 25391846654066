import React from "react";

interface Props {}

export const HomePage = (props: Props) => {
  return (
    <section className="page padded">
      <h1>Soundboard Under Construction</h1>
      <p>Vi har brug for disse ting:</p>
      <ul>
        <li>
          Build better soundboard than the RTL (Royal Tuneo League or something
          like that)
        </li>
        <li>Make Tuna upload all the fucking sounds</li>
        <li>Remix everything</li>
      </ul>
    </section>
  );
};
