"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * BetterSoundboard.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StreamToJSON = exports.StreamFromJSONTyped = exports.StreamFromJSON = exports.instanceOfStream = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the Stream interface.
 */
function instanceOfStream(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfStream = instanceOfStream;
function StreamFromJSON(json) {
    return StreamFromJSONTyped(json, false);
}
exports.StreamFromJSON = StreamFromJSON;
function StreamFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'canRead': !(0, runtime_1.exists)(json, 'canRead') ? undefined : json['canRead'],
        'canWrite': !(0, runtime_1.exists)(json, 'canWrite') ? undefined : json['canWrite'],
        'canSeek': !(0, runtime_1.exists)(json, 'canSeek') ? undefined : json['canSeek'],
        'canTimeout': !(0, runtime_1.exists)(json, 'canTimeout') ? undefined : json['canTimeout'],
        'length': !(0, runtime_1.exists)(json, 'length') ? undefined : json['length'],
        'position': !(0, runtime_1.exists)(json, 'position') ? undefined : json['position'],
        'readTimeout': !(0, runtime_1.exists)(json, 'readTimeout') ? undefined : json['readTimeout'],
        'writeTimeout': !(0, runtime_1.exists)(json, 'writeTimeout') ? undefined : json['writeTimeout'],
    };
}
exports.StreamFromJSONTyped = StreamFromJSONTyped;
function StreamToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'position': value.position,
        'readTimeout': value.readTimeout,
        'writeTimeout': value.writeTimeout,
    };
}
exports.StreamToJSON = StreamToJSON;
