import { DefaultValue, atom, selector } from "recoil";
import { AudioFile, AudioFileSolrDocument } from "soundboard-api";
import { IQueueItem, queueAtom } from "./QueueAtom";

export interface CurrentlyPlayingState {
  audioFile: AudioFileSolrDocument;
}

export const currentlyPlayingSelector = selector<IQueueItem | null>({
  key: "CurrentlyPlaying",
  set: ({ get, set }, newValue) => {
    const queue = get(queueAtom);
    if (newValue && !(newValue instanceof DefaultValue)) {
      const queueItem: IQueueItem = {
        id: newValue.id,
        sound: newValue.sound,
        queueKey: newValue.queueKey
      };
      let oldItems = queue.items;
      let oldHistory = queue.history;
      if (oldItems.length) {
        const [currentItem, ...rest] = oldItems;
        oldItems = rest;
        oldHistory = [currentItem, ...oldHistory];
      }
      const newItems = [queueItem, ...oldItems];
      set(queueAtom, { history: oldHistory, items: newItems });
    }
  },
  get: ({ get }) => {
    const queue = get(queueAtom);
    return queue.items.at(0) ?? null;
  }
});
