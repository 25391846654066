import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { searchQueryAtom } from "../../atoms/SearchSelector";

interface Props {}

export const SearchStateHandler = (props: Props) => {
  const [query, setQuery] = useRecoilState(searchQueryAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  // useEffect(() => {
  //     if (searchParams.has("search")) {
  //       setQuery({ query: searchParams.get("search")! });
  //     }
  //   }, [searchParams, setQuery]);

  useEffect(() => {
    if (searchParams.has("search")) {
      setQuery({ query: searchParams.get("search")! });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setQuery]);

  useEffect(() => {
    if (query.query) {
      setSearchParams((prev) => {
        prev.set("search", query.query);
        return prev;
      });
    }
  }, [query, searchParams, setSearchParams]);

  return <div className="search-state-handler" />;
};
