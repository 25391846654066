import React from "react";
import "./MediaMenu.scss";
import SearchIcon from "@mui/icons-material/Search";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import { useRecoilState } from "recoil";
import { queueAtom } from "../../atoms/QueueAtom";
import { useNavigate } from "react-router-dom";

interface Props {}

export const MediaMenu = (props: Props) => {
  const [queue, setQueue] = useRecoilState(queueAtom);
  const navigate = useNavigate();
  return (
    <div className="player-menu">
      <div className="primary">
        <ul>
          <li onClick={() => navigate("/media/search")}>
            <SearchIcon /> Search
          </li>
          <li onClick={() => navigate("/media/queue")}>
            <QueueMusicIcon /> Queue
          </li>
        </ul>
      </div>
      <div className="secondary">
        <ul>
          {/* <li>Test 1</li>
          <li>Test 2</li> */}
        </ul>
      </div>
    </div>
  );
};
