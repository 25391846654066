import React from "react";
import { Outlet } from "react-router-dom";
import { MediaMenu } from "../components/media-menu/MediaMenu";

interface Props {}

export const MediaPage = (props: Props) => {
  return (
    <section className="page player-page">
      <div className="menu">
        <MediaMenu />
      </div>
      <div className="content">
        <Outlet />
      </div>
    </section>
  );
};
