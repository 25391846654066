import React from "react";
import { useAuthentication } from "../../utils/useAuthentication";

interface Props {
  children?: React.ReactNode;
  allowedRoles?: string[];
}

export const Restrict = (props: Props) => {
  const { profile, isAuthenticated } = useAuthentication();
  const cleanAllowedRoles = props.allowedRoles?.map((x) => x.toLowerCase());
  if (props.allowedRoles) {
    if (
      profile?.roles?.some((x) => cleanAllowedRoles!.includes(x.toLowerCase()))
    ) {
      return <>{props.children}</>;
    } else return null;
  } else if (isAuthenticated) return <>{props.children}</>;

  return null;
};
