import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import React from "react";
import { SortField } from "../../atoms/SearchSelector";
import "./SearchList.scss";

interface Props {
  sortField?: SortField;
  sortDirection?: "asc" | "desc";
  handleSort: (field: SortField) => void;
}

export const SearchListHeader = (props: Props) => {
  return (
    <TableHead className="search-list-header">
      <TableRow>
        <TableCell className="title" sortDirection={props.sortDirection}>
          <TableSortLabel
            active={props.sortField === "title"}
            direction={props.sortDirection}
            onClick={(e) => props.handleSort("title")}
          >
            Title
          </TableSortLabel>
        </TableCell>
        <TableCell className="authors">Authors</TableCell>
        <TableCell className="type" sortDirection={props.sortDirection}>
          <TableSortLabel
            active={props.sortField === "extension"}
            direction={props.sortDirection}
            onClick={(e) => props.handleSort("extension")}
          >
            Type
          </TableSortLabel>
        </TableCell>
        <TableCell className="age" sortDirection={props.sortDirection}>
          <TableSortLabel
            active={props.sortField === "age"}
            direction={props.sortDirection}
            onClick={(e) => props.handleSort("age")}
          >
            Age
          </TableSortLabel>
        </TableCell>
        <TableCell className="duration" sortDirection={props.sortDirection}>
          <TableSortLabel
            active={props.sortField === "duration"}
            direction={props.sortDirection}
            onClick={(e) => props.handleSort("duration")}
          >
            Length
          </TableSortLabel>
        </TableCell>
        <TableCell className="actions"></TableCell>
      </TableRow>
    </TableHead>
  );
};
