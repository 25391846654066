import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "./ErrorModal.scss";

interface Props {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

export const ErrorModal = (props: Props) => {
  return (
    <Modal className="error-modal" open={props.open} onClose={props.onClose}>
      <div className="error-container">
        <header className="error-header">
          <h1>An error has occurred</h1>
          <h2>But you are a big boi, here is the error:</h2>
        </header>
        <div className="error-str">{props.children}</div>
      </div>
    </Modal>
  );
};
