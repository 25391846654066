"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * BetterSoundboard.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeSpanToJSON = exports.TimeSpanFromJSONTyped = exports.TimeSpanFromJSON = exports.instanceOfTimeSpan = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the TimeSpan interface.
 */
function instanceOfTimeSpan(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfTimeSpan = instanceOfTimeSpan;
function TimeSpanFromJSON(json) {
    return TimeSpanFromJSONTyped(json, false);
}
exports.TimeSpanFromJSON = TimeSpanFromJSON;
function TimeSpanFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticks': !(0, runtime_1.exists)(json, 'ticks') ? undefined : json['ticks'],
        'days': !(0, runtime_1.exists)(json, 'days') ? undefined : json['days'],
        'hours': !(0, runtime_1.exists)(json, 'hours') ? undefined : json['hours'],
        'milliseconds': !(0, runtime_1.exists)(json, 'milliseconds') ? undefined : json['milliseconds'],
        'minutes': !(0, runtime_1.exists)(json, 'minutes') ? undefined : json['minutes'],
        'seconds': !(0, runtime_1.exists)(json, 'seconds') ? undefined : json['seconds'],
        'totalDays': !(0, runtime_1.exists)(json, 'totalDays') ? undefined : json['totalDays'],
        'totalHours': !(0, runtime_1.exists)(json, 'totalHours') ? undefined : json['totalHours'],
        'totalMilliseconds': !(0, runtime_1.exists)(json, 'totalMilliseconds') ? undefined : json['totalMilliseconds'],
        'totalMinutes': !(0, runtime_1.exists)(json, 'totalMinutes') ? undefined : json['totalMinutes'],
        'totalSeconds': !(0, runtime_1.exists)(json, 'totalSeconds') ? undefined : json['totalSeconds'],
    };
}
exports.TimeSpanFromJSONTyped = TimeSpanFromJSONTyped;
function TimeSpanToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ticks': value.ticks,
    };
}
exports.TimeSpanToJSON = TimeSpanToJSON;
