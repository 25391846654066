import React from "react";
import { useRecoilValue } from "recoil";
import { filteredSoundFilesSelector } from "../../atoms/SoundFileSelector";
import { useAuthentication } from "../../utils/useAuthentication";
import { Sound } from "./Sound";
import "./SoundGrid.scss";

interface Props {}

export const SoundGrid = (props: Props) => {
  const { token } = useAuthentication();
  const sounds = useRecoilValue(filteredSoundFilesSelector(token));

  return (
    <div className="sound-grid">
      {sounds.map((audioFile) => (
        <Sound key={audioFile.id} file={audioFile} />
      ))}
    </div>
  );
};
