import React, { useEffect, useState } from "react";
import "./Player.scss";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import Replay10Icon from "@mui/icons-material/Replay10";
import Forward10Icon from "@mui/icons-material/Forward10";
import PauseIcon from "@mui/icons-material/Pause";
import { useMediaControls } from "react-flat-player";

interface Props {
  audioRef: React.RefObject<HTMLAudioElement>;
  onSkipNext?: () => void;
  onSkipPrev?: () => void;
}

export const PlayerControls = (props: Props) => {
  const { paused, pause, forward10, rewind10 } = useMediaControls(
    props.audioRef
  );

  return (
    <div className="controls">
      {props.onSkipPrev && (
        <div className="skipprevious" onClick={props.onSkipPrev}>
          <SkipPreviousIcon />
        </div>
      )}
      <div className="replay10" onClick={() => rewind10()}>
        <Replay10Icon />
      </div>
      <div className="playpause" onClick={() => pause()}>
        {!paused ? <PauseIcon /> : <PlayArrowIcon />}
      </div>
      <div className="forward10" onClick={() => forward10()}>
        <Forward10Icon />
      </div>
      {props.onSkipNext && (
        <div className="skipnext" onClick={props.onSkipNext}>
          <SkipNextIcon />
        </div>
      )}
    </div>
  );
};
