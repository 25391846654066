import { useAuth, User } from "oidc-react";
import { LoginState } from "../interfaces/LoginState";

export function useAuthentication() {
  const auth = useAuth();
  const { signIn, signOut, isLoading, userData, userManager } = auth;

  const getLoginState = () => {
    const loginState: LoginState = {
      returnUrl: window.location.href
    };
    return loginState;
  };

  const signInWithRedirect = () =>
    userManager.signinRedirect({ state: getLoginState() });
  const isAuthenticated = Boolean(userData && !isLoading);
  const token = userData?.access_token;

  const profile = userData?.profile as { roles: string[] } | undefined;

  const getToken = () => {
    return token;
  };

  return {
    signIn,
    signInWithRedirect,
    signOut,
    isAuthenticated,
    isLoading,
    userData,
    profile,
    userManager,
    token,
    getToken
  };
}
