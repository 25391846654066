"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * BetterSoundboard.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserToJSON = exports.UserFromJSONTyped = exports.UserFromJSON = exports.instanceOfUser = void 0;
/**
 * Check if a given object implements the User interface.
 */
function instanceOfUser(value) {
    var isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
exports.instanceOfUser = instanceOfUser;
function UserFromJSON(json) {
    return UserFromJSONTyped(json, false);
}
exports.UserFromJSON = UserFromJSON;
function UserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': json['userId'],
        'name': json['name'],
    };
}
exports.UserFromJSONTyped = UserFromJSONTyped;
function UserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'name': value.name,
    };
}
exports.UserToJSON = UserToJSON;
