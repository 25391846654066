import { TextField } from "@mui/material";
import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { soundFileQueryAtom } from "../../atoms/SoundFileSelector";
import "./SoundSearch.scss";

interface Props {
  onQuery?: (query: string) => void;
}

export const SoundSearch = (props: Props) => {
  const [searchQuery, setSearchQuery] = useRecoilState(soundFileQueryAtom);

  const handleQueryChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchQuery(e.currentTarget.value);
  };

  return (
    <div className="sound-search">
      <div className="search-container">
        <TextField
          hiddenLabel
          placeholder="Search"
          className="search-input"
          variant="filled"
          onChange={handleQueryChange}
        />
      </div>
    </div>
  );
};
