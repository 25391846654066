import React, { ChangeEvent, useEffect, useState } from "react";
import "./ProgressSlider.scss";
import { formatTime } from "../../utils/formatTime";

interface Props {
  audioRef: React.RefObject<HTMLAudioElement>;
}

export const ProgressSlider = (props: Props) => {
  const [duration, setDuration] = useState(props.audioRef.current?.duration);
  const [currentTime, setCurrentTime] = useState(
    props.audioRef.current?.currentTime ?? 0
  );
  const [seeking, setSeeking] = useState(false);
  // const [pauseState, setPauseState] = useState<boolean>();

  useEffect(() => {
    const handleMetadata = (e: Event) => {
      const audioElement = e.currentTarget as HTMLAudioElement;
      setDuration(audioElement.duration);
    };
    const interval = setInterval(() => {
      if (props.audioRef.current && !seeking) {
        setCurrentTime(props.audioRef.current.currentTime);
      }
    }, 250);

    props.audioRef.current?.addEventListener("loadedmetadata", handleMetadata);
    return () => {
      clearInterval(interval);
      props.audioRef.current?.removeEventListener(
        "loadedmetadata",
        handleMetadata
      );
    };
  }, [props.audioRef, seeking]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentTime(e.currentTarget.valueAsNumber);
  };

  useEffect(() => {
    if (props.audioRef.current && seeking) {
      props.audioRef.current.currentTime = currentTime;
    }
  }, [currentTime, props.audioRef, seeking]);

  let width = (+currentTime.toFixed(2) / +(duration ?? 1).toFixed(2)) * 100;
  if (width > 100) width = 100;
  return (
    <div className="progress-slider">
      <span className="time-elapsed">{formatTime(currentTime)}</span>
      <div className="input-container">
        <div
          className={"slider" + (seeking ? " seeking" : "")}
          style={{ width: `${width.toFixed(2)}%` }}
        />
        <input
          onMouseDown={() => setSeeking(true)}
          onMouseUp={() => {
            setSeeking(false);
          }}
          className="progress-input"
          min={0}
          max={(duration ?? 1).toFixed(2)}
          step={0.01}
          type="range"
          value={currentTime}
          onChange={handleChange}
        />
      </div>
      <span className="duration">
        {duration ? formatTime(duration) : "00:00"}
      </span>
    </div>
  );
};
// {/* <div className="seeker">
//   <div className="slider" />
//   <input className="seeker-input" type={"range"}></input>
// </div> */}
