import { createRoutesFromElements, Route } from "react-router-dom";
import App from "./App";
import { Restrict } from "./components/auth/Restrict";
import { AddSoundsDialog } from "./components/forms/AddSoundsDialog";
import { DeleteSoundDialog } from "./components/forms/DeleteSoundDialog";
import { EditSoundDialog } from "./components/forms/EditSoundDialog";
import { DebugPage } from "./pages/DebugPage";
import { HomePage } from "./pages/HomePage";
import { SearchPage } from "./pages/SearchPage";
import { SoundsPage } from "./pages/SoundsPage";
import { MediaPage } from "./pages/MediaPage";
import { SearchList } from "./components/search/SearchList";
import { Queue } from "./components/queue/Queue";

export const routes = createRoutesFromElements(
  <Route path="/" element={<App />}>
    <Route index element={<HomePage />} />
    <Route
      path="debug"
      element={
        <Restrict allowedRoles={["admin"]}>
          <DebugPage />
        </Restrict>
      }
    />
    <Route
      path="search"
      element={
        <Restrict allowedRoles={["admin"]}>
          <SearchPage />
        </Restrict>
      }
    />
    <Route
      path="media"
      element={
        <Restrict>
          <MediaPage />
        </Restrict>
      }
    >
      <Route index element={<h1>Placeholder</h1>} />
      <Route path="search" element={<SearchList />} />
      <Route path="queue" element={<Queue />} />
    </Route>
    <Route
      path="sounds"
      element={
        <Restrict>
          <SoundsPage />
        </Restrict>
      }
    >
      <Route path="edit/:fileId" element={<EditSoundDialog />} />
      <Route path="delete/:fileId" element={<DeleteSoundDialog />} />
    </Route>
  </Route>
);
