"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * BetterSoundboard.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudioFileSolrDocumentToJSON = exports.AudioFileSolrDocumentFromJSONTyped = exports.AudioFileSolrDocumentFromJSON = exports.instanceOfAudioFileSolrDocument = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the AudioFileSolrDocument interface.
 */
function instanceOfAudioFileSolrDocument(value) {
    var isInstance = true;
    isInstance = isInstance && "audioFileId" in value;
    isInstance = isInstance && "audioFileBlobId" in value;
    isInstance = isInstance && "audioFileName" in value;
    isInstance = isInstance && "audioFileExtension" in value;
    isInstance = isInstance && "audioFileMimeType" in value;
    isInstance = isInstance && "audioFileAuthorIds" in value;
    isInstance = isInstance && "audioFileAuthorNames" in value;
    isInstance = isInstance && "audioFileCreatedAt" in value;
    isInstance = isInstance && "audioFileLastUpdatedAt" in value;
    isInstance = isInstance && "audioFileDuration" in value;
    return isInstance;
}
exports.instanceOfAudioFileSolrDocument = instanceOfAudioFileSolrDocument;
function AudioFileSolrDocumentFromJSON(json) {
    return AudioFileSolrDocumentFromJSONTyped(json, false);
}
exports.AudioFileSolrDocumentFromJSON = AudioFileSolrDocumentFromJSON;
function AudioFileSolrDocumentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'audioFileId': json['audioFileId'],
        'audioFileBlobId': json['audioFileBlobId'],
        'audioFileName': json['audioFileName'],
        'audioFileDescription': !(0, runtime_1.exists)(json, 'audioFileDescription') ? undefined : json['audioFileDescription'],
        'audioFileExtension': json['audioFileExtension'],
        'audioFileMimeType': json['audioFileMimeType'],
        'audioFileAuthorIds': json['audioFileAuthorIds'],
        'audioFileAuthorNames': json['audioFileAuthorNames'],
        'audioFileCreatedAt': (new Date(json['audioFileCreatedAt'])),
        'audioFileLastUpdatedAt': (new Date(json['audioFileLastUpdatedAt'])),
        'audioFileDuration': json['audioFileDuration'],
    };
}
exports.AudioFileSolrDocumentFromJSONTyped = AudioFileSolrDocumentFromJSONTyped;
function AudioFileSolrDocumentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'audioFileId': value.audioFileId,
        'audioFileBlobId': value.audioFileBlobId,
        'audioFileName': value.audioFileName,
        'audioFileDescription': value.audioFileDescription,
        'audioFileExtension': value.audioFileExtension,
        'audioFileMimeType': value.audioFileMimeType,
        'audioFileAuthorIds': value.audioFileAuthorIds,
        'audioFileAuthorNames': value.audioFileAuthorNames,
        'audioFileCreatedAt': (value.audioFileCreatedAt.toISOString()),
        'audioFileLastUpdatedAt': (value.audioFileLastUpdatedAt.toISOString()),
        'audioFileDuration': value.audioFileDuration,
    };
}
exports.AudioFileSolrDocumentToJSON = AudioFileSolrDocumentToJSON;
