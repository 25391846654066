import { atom, selectorFamily } from "recoil";
import { AudioFile, FilesApi } from "soundboard-api";
import { CreateApi } from "../utils/CreateApi";
import { tokenize } from "../utils/Tokenize";

interface QueryString {
  id: string;
  queryString: string;
}

export const filteredSoundFilesSelector = selectorFamily<
  AudioFile[],
  string | undefined
>({
  key: "FilteredSoundFiles",
  get:
    (token) =>
    async ({ get }) => {
      const sounds = get(soundFilesSelector(token));
      const query = get(soundFileQueryAtom);
      if (!query) return sounds;

      const queryStrings = sounds.reduce<QueryString[]>((prev, sound) => {
        const qs = [sound.author.name, sound.name, sound.extension].join(" ");
        const tokens = tokenize(qs);
        const queryString = tokens
          .reduce((prev, curr) => {
            if (prev.includes(curr)) return prev;
            else return prev + " " + curr;
          })
          .trim();
        const q: QueryString = {
          id: sound.id,
          queryString: queryString
        };
        return [...prev, q];
      }, []);
      const tokens = tokenize(query);

      const matches = tokens.reduce((prev, curr, i, arr) => {
        const filtered = prev.filter((x) => x.queryString.includes(curr));
        return filtered;
      }, queryStrings);

      return sounds.filter((x) => matches.find((m) => m.id === x.id));
    }
});

export const soundFilesSelector = selectorFamily<
  AudioFile[],
  string | undefined
>({
  key: "SoundFiles",
  get:
    (token) =>
    async ({ get }) => {
      const api = CreateApi(FilesApi, token);
      const files = await api.apiFilesGet();
      return files;
    }
});

export const soundFileSelector = selectorFamily<
  AudioFile,
  { token?: string; fileId?: string }
>({
  key: "SoundFile",
  get:
    (params) =>
    async ({ get }) => {
      const files = get(soundFilesSelector(params.token));
      const file = files.find((x) => x.id === params.fileId);
      if (file === undefined) throw new Error("File cannot be undefined");
      return file;
    }
});

export const soundFileQueryAtom = atom<string>({
  key: "SoundFileQuery",
  default: undefined
});
