import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { AudioFileSolrDocument } from "soundboard-api";
import { DateTime } from "luxon";
import humanizeDuration, { humanizer } from "humanize-duration";
import "./SearchList.scss";
import { useRecoilState, useRecoilValue } from "recoil";
import { soundFileSelector } from "../../atoms/SoundFileSelector";
import { useAuthentication } from "../../utils/useAuthentication";
import { currentlyPlayingSelector } from "../../atoms/CurrentlyPlayingAtom";
import { v4 as uuidv4 } from "uuid";

interface Props {
  sound: AudioFileSolrDocument;
  active?: boolean;
  onClick?: (sound: AudioFileSolrDocument) => void;
}

export const SearchListItem = (props: Props) => {
  const [currentlyPlaying, setCurrentlyPlaying] = useRecoilState(
    currentlyPlayingSelector
  );

  const handleClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    setCurrentlyPlaying({
      id: props.sound.audioFileId,
      sound: props.sound,
      queueKey: uuidv4()
    });
  };

  const createdAt = DateTime.fromJSDate(
    props.sound.audioFileCreatedAt
  ).toMillis();
  const now = DateTime.now().toMillis();

  const humanizeDate = humanizer({
    largest: 2,
    delimiter: " ",
    round: true
  });

  const humanizeDuration = (seconds: number) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds / 60) % 60);
    const s = seconds % 60;
    const timeArr: string[] = [];
    if (h > 0) timeArr.push(h.toFixed(0));
    timeArr.push(m.toFixed(0).padStart(1, "0"));
    timeArr.push(s.toFixed(0).padStart(2, "0"));
    return timeArr.join(":");
  };

  return (
    <TableRow
      onClick={() => props.onClick && props.onClick(props.sound)}
      onDoubleClick={handleClick}
      className={`table-row ${props.active ? "active" : ""}`.trim()}
    >
      <TableCell>{props.sound.audioFileName}</TableCell>
      <TableCell>{props.sound.audioFileAuthorNames.join(" - ")}</TableCell>
      <TableCell>{props.sound.audioFileExtension}</TableCell>
      <TableCell>{humanizeDate(now - createdAt)} ago</TableCell>
      <TableCell>{humanizeDuration(props.sound.audioFileDuration)}</TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
};
