import React, { createRef, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
// import { currentlyPlayingAtom } from "../../atoms/CurrentlyPlayingAtom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import Replay10Icon from "@mui/icons-material/Replay10";
import Forward10Icon from "@mui/icons-material/Forward10";
import PauseIcon from "@mui/icons-material/Pause";
import "./Player.scss";
import { VolumeSlider } from "./VolumeSlider";
import LoopIcon from "@mui/icons-material/Loop";
import { ProgressSlider } from "./ProgressSlider";
import { PlayerControls } from "./PlayerControls";
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Queue, queueAtom } from "../../atoms/QueueAtom";
import { currentlyPlayingSelector } from "../../atoms/CurrentlyPlayingAtom";

interface Props {}

export const Player = (props: Props) => {
  const [currentlyPlaying, setCurrentlyPlaying] = useRecoilState(
    currentlyPlayingSelector
  );
  const audioRef = createRef<HTMLAudioElement>();
  const [queue, setQueue] = useRecoilState(queueAtom);
  const [currentQueueKey, setCurrentQueueKey] = useState("");
  const disabled = currentQueueKey === "";

  const handleNext = () => {
    setQueue((prev) => {
      const [prevSound, ...items] = prev.items;
      const history = [prevSound, ...prev.history].filter((x) => Boolean(x));
      return { items, history };
    });
  };
  const handlePrev = () => {
    if (audioRef.current) {
      if (audioRef.current.currentTime <= 5) {
        setQueue((prev) => {
          const [nextSound, ...history] = prev.history;
          const items = [nextSound, ...prev.items].filter((x) => Boolean(x));
          return { history, items };
        });
      } else {
        audioRef.current.currentTime = 0;
      }
    }
  };

  // useEffect(() => {
  //   if (currentlyPlaying && audioRef.current) {
  //     // const url = `/api/files/stream/${currentlyPlaying.audioFile.audioFileId}`;
  //     // audioRef.current.src = url;
  //     audioRef.current.play();
  //   } else if (audioRef.current) {
  //     audioRef.current.pause();
  //     audioRef.current.src = "";
  //   }
  // }, [audioRef, currentlyPlaying]);

  useEffect(() => {
    if (audioRef.current) {
      setCurrentQueueKey((prev) => {
        const key = currentlyPlaying?.queueKey ?? "";
        if (prev !== key) {
          audioRef.current?.play();
          return key;
        }
        return prev;
      });
    }
  }, [audioRef, currentlyPlaying?.queueKey]);

  useEffect(() => {
    if (!currentlyPlaying && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = "";
    }
  }, [audioRef, currentlyPlaying]);

  const handleSoundEnded = (
    e: React.SyntheticEvent<HTMLAudioElement, Event>
  ) => {
    handleNext();
    // setQueue({ items: queue.items, currentIndex: next });
  };

  return (
    <div className="site-player">
      <div className="player-info">
        <div className="thumbnail">
          <QuestionMarkIcon className="no-thumbnail" />
        </div>
        <div className="text">
          <h1>{currentlyPlaying?.sound.audioFileName}</h1>
          <h2>{currentlyPlaying?.sound.audioFileAuthorNames.join(", ")}</h2>
        </div>
      </div>
      <div className="player-controls">
        <PlayerControls
          audioRef={audioRef}
          onSkipNext={handleNext}
          onSkipPrev={handlePrev}
        />
        <div className="progress-container">
          <ProgressSlider audioRef={audioRef} />
        </div>
      </div>
      <div className="audio-controls">
        <div className="controls">
          {/* <div className="queue">
            <QueueOutlinedIcon />
          </div>
          <div className="loop">
            <LoopIcon />
          </div> */}
        </div>
        <div className="volume">
          <VolumeSlider audioRef={audioRef} />
        </div>
      </div>
      <audio
        src={
          currentlyPlaying
            ? `/api/files/stream/${currentlyPlaying.sound.audioFileId}`
            : undefined
        }
        ref={audioRef}
        onEnded={handleSoundEnded}
      />
    </div>
  );
};
