import React from "react";
import { useSearchParams } from "react-router-dom";
import { AddSoundsDialog } from "./forms/AddSoundsDialog";

interface Props {}

export const RouteActionHandler = (props: Props) => {
  // <Route path="edit/:fileId" element={<EditSoundDialog />} />
  // <Route path="delete/:fileId" element={<DeleteSoundDialog />} />
  // <Route path="add" element={<AddSoundsDialog />} />

  const [searchParams, setSearchParams] = useSearchParams();

  const action = searchParams.get("action");

  switch (action?.toLowerCase()) {
    case "upload":
      return <AddSoundsDialog />;

    default:
      return null;
  }
};
