import { atom } from "recoil";
import { AudioFileSolrDocument } from "soundboard-api";

export interface Queue {
  items: IQueueItem[];
  history: IQueueItem[];
}

export interface IQueueItem {
  queueKey: string;
  id: string;
  sound: AudioFileSolrDocument;
}

export const queueAtom = atom<Queue>({
  key: "QueueState",
  default: {
    items: [],
    history: []
  }
});
