import { Dialog } from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AddSoundsForm } from "./AddSoundsForm";

interface Props {}

export const AddSoundsDialog = (props: Props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    // navigate("/Sounds");
    setSearchParams((prev) => {
      prev.delete("action");
      return prev;
    });
    // navigate(-1);
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <AddSoundsForm onClose={handleClose} />
    </Dialog>
  );
};
