import React, { Suspense } from "react";
import { AppBar, Toolbar } from "@mui/material";
import { Link, Outlet, useSearchParams } from "react-router-dom";
import { LoginButton } from "./auth/LoginButton";
import "./Layout.scss";
import { Restrict } from "./auth/Restrict";
import { useRecoilState } from "recoil";
import { errorAtom } from "../atoms/ErrorAtom";
import { ErrorModal } from "./ErrorModal";
import { SearchInput } from "./search/SearchInput";
import { SearchStateHandler } from "./search/SearchStateHandler";
import { Player } from "./site-player/Player";
import { RouteActionHandler } from "./RouteActionHandler";

interface Props {}

export const Layout = (props: Props) => {
  const [error, setError] = useRecoilState(errorAtom);
  const [, setSearchParams] = useSearchParams();
  return (
    <section className="layout">
      <nav className="main-nav">
        <AppBar>
          <Toolbar disableGutters className="toolbar">
            <Link to="/">
              <h1 className="header">BetterSoundboard</h1>
            </Link>
            <ul className="nav-items">
              <Restrict>
                <li>
                  <Link to={"/Sounds"}>Grid View</Link>
                </li>
              </Restrict>
              <Restrict>
                <li>
                  <Link to={"/Media"}>Media</Link>
                </li>
              </Restrict>
              <Restrict allowedRoles={["Admin"]}>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSearchParams((prev) => {
                      prev.set("action", "upload");
                      return prev;
                    });
                  }}
                >
                  Upload
                  {/* <Link to={"/Sounds/Add"}>Upload</Link> */}
                </li>
              </Restrict>
              {/* <li>
                <Link to={"/Debug"}>Debug</Link>
              </li> */}
            </ul>
            <div className="menu">
              <SearchInput />
              <LoginButton />
            </div>
          </Toolbar>
        </AppBar>
      </nav>
      <Outlet />
      {error && (
        <ErrorModal open={Boolean(error)} onClose={() => setError(undefined)}>
          <pre>{error}</pre>
        </ErrorModal>
      )}
      <Suspense>
        <RouteActionHandler />
      </Suspense>
      <footer className="page-footer">
        {/* <CurrentlyPlaying /> */}
        <Player />
      </footer>
      <SearchStateHandler />
    </section>
  );
};
