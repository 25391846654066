import { Divider, Table, TableBody } from "@mui/material";
import React, { Suspense, useState } from "react";
import { useRecoilState } from "recoil";
import { searchQueryAtom, SortField } from "../../atoms/SearchSelector";
import { SearchListBody, SearchListBodyFallback } from "./SearchListBody";
import { SearchListHeader } from "./SearchListHeader";
import { SearchListPagination } from "./SearchListPagination";
import "./SearchList.scss";
import { SearchListSelection } from "./SearchListSelection";
import { AudioFileSolrDocument } from "soundboard-api";

interface Props {}

export const SearchList = (props: Props) => {
  const [query, setQuery] = useRecoilState(searchQueryAtom);
  const [selectedItem, setSelectedItem] = useState<AudioFileSolrDocument>();
  const [totalCount, setTotalCount] = useState(23);

  const handleSort = (field: SortField) => {
    if (field === query.sortField) {
      if (query.sortDirection === "desc") {
        setQuery({ ...query, sortField: "score", sortDirection: "desc" });
      } else {
        setQuery({ ...query, sortDirection: "desc" });
      }
      switch (query.sortDirection) {
      }
    } else {
      setQuery({ ...query, sortField: field, sortDirection: "asc" });
    }
  };

  const rowsPerPageOptions = [10, 25, 50];
  return (
    <div className="search-list-container">
      <SearchListSelection selection={selectedItem} />
      <Divider />
      <div className="search-list">
        <Table className="search-list-table">
          <SearchListHeader
            sortField={query.sortField}
            sortDirection={query.sortDirection}
            handleSort={handleSort}
          />
          <TableBody className="search-list-table-body">
            <Suspense
              fallback={
                <SearchListBodyFallback
                  count={query.rows ?? rowsPerPageOptions[0]}
                />
              }
            >
              <SearchListBody
                selectedItem={selectedItem}
                onSelection={(sound) => setSelectedItem(sound)}
                onSearchChange={(search) =>
                  setTotalCount(search?.totalCount ?? 0)
                }
              />
            </Suspense>
          </TableBody>
        </Table>
        <SearchListPagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={totalCount}
        />
      </div>
    </div>
  );
};
