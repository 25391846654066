import { selectorFamily } from "recoil";
import { BootstrapApi } from "soundboard-api";
import { CreateApi } from "../utils/CreateApi";

export const mimeTypeSelector = selectorFamily<string[], string | undefined>({
  key: "mimeTypes",
  get:
    (token) =>
    async ({ get }) => {
      const api = CreateApi(BootstrapApi, token);
      const mimeTypes = await api.apiBootstrapMimetypesGet();
      return mimeTypes;
    }
});
