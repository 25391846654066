import {
  BaseAPI,
  Configuration,
  ConfigurationParameters
} from "soundboard-api";

export function CreateApi<T extends BaseAPI>(
  type: new (configuration?: Configuration | undefined) => T,
  token?: string
) {
  const configParams: ConfigurationParameters = {
    basePath: origin
  };

  if (token) {
    configParams.headers = {
      Authorization: `Bearer ${token}`
    };
  }

  const config = new Configuration(configParams);
  var api = new type(config);
  return api;
}
