import { atom, selectorFamily } from "recoil";
import {
  AudioFileSolrDocument,
  SearchApi,
  SearchResultDto
} from "soundboard-api";
import { CreateApi } from "../utils/CreateApi";

export const searchSelector = selectorFamily<
  SearchResultDto | null,
  string | undefined
>({
  key: "Search",
  get:
    (token) =>
    async ({ get }) => {
      const query = get(searchQueryAtom);
      if (query.query) {
        const api = CreateApi(SearchApi, token);
        const searchResult = await api.apiSearchPost({
          queryDto: { query: query.query },
          rows: query.rows,
          sortDirection: query.sortDirection,
          sortField: query.sortField,
          start: query.start
        });
        return searchResult;
      }
      return null;
    }
});

export type SortField = "score" | "title" | "extension" | "age" | "duration";
export interface SearchQuery {
  query: string;
  rows?: number;
  start?: number;
  sortField?: SortField;
  sortDirection?: "asc" | "desc";
}

export const searchQueryAtom = atom<SearchQuery>({
  key: "SearchQuery",
  default: { query: "" }
});
