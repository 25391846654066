"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * BetterSoundboard.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudioFileToJSON = exports.AudioFileFromJSONTyped = exports.AudioFileFromJSON = exports.instanceOfAudioFile = void 0;
var runtime_1 = require("../runtime");
var TimeSpan_1 = require("./TimeSpan");
var User_1 = require("./User");
/**
 * Check if a given object implements the AudioFile interface.
 */
function instanceOfAudioFile(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "blobId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "extension" in value;
    isInstance = isInstance && "mimeType" in value;
    isInstance = isInstance && "author" in value;
    isInstance = isInstance && "authorId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "lastUpdatedAt" in value;
    isInstance = isInstance && "duration" in value;
    return isInstance;
}
exports.instanceOfAudioFile = instanceOfAudioFile;
function AudioFileFromJSON(json) {
    return AudioFileFromJSONTyped(json, false);
}
exports.AudioFileFromJSON = AudioFileFromJSON;
function AudioFileFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'blobId': json['blobId'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'extension': json['extension'],
        'mimeType': json['mimeType'],
        'author': (0, User_1.UserFromJSON)(json['author']),
        'authorId': json['authorId'],
        'createdAt': (new Date(json['createdAt'])),
        'lastUpdatedAt': (new Date(json['lastUpdatedAt'])),
        'duration': (0, TimeSpan_1.TimeSpanFromJSON)(json['duration']),
    };
}
exports.AudioFileFromJSONTyped = AudioFileFromJSONTyped;
function AudioFileToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'blobId': value.blobId,
        'name': value.name,
        'description': value.description,
        'extension': value.extension,
        'mimeType': value.mimeType,
        'author': (0, User_1.UserToJSON)(value.author),
        'authorId': value.authorId,
        'createdAt': (value.createdAt.toISOString()),
        'lastUpdatedAt': (value.lastUpdatedAt.toISOString()),
        'duration': (0, TimeSpan_1.TimeSpanToJSON)(value.duration),
    };
}
exports.AudioFileToJSON = AudioFileToJSON;
