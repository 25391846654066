import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue
} from "recoil";
import { FilesApi } from "soundboard-api";
import { errorAtom } from "../../atoms/ErrorAtom";
import {
  soundFileSelector,
  soundFilesSelector
} from "../../atoms/SoundFileSelector";
import { useApi } from "../../utils/useApi";
import { useAuthentication } from "../../utils/useAuthentication";

interface Props {}

export const DeleteSoundDialog = (props: Props) => {
  const { token } = useAuthentication();
  const api = useApi(FilesApi, token);
  const navigate = useNavigate();
  const { fileId } = useParams();
  const refresh = useRecoilRefresher_UNSTABLE(soundFilesSelector(token));
  const [error, setError] = useRecoilState(errorAtom);

  const file = useRecoilValue(
    soundFileSelector({ token: token, fileId: fileId })
  );

  const confirmDelete = async () => {
    try {
      await api.apiFilesFileIdDelete({ fileId: file.id });
      refresh();
      handleClose();
    } catch (e) {
      setError((e as Error).message);
    }
  };

  const handleClose = () => {
    navigate("/Sounds");
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle> Are you sure you wish to delete this sound?</DialogTitle>
      <DialogContent>
        <p>Id: {file.id}</p>
        <p>Name: {file.name}</p>
        <p>Extension: {file.extension}</p>
        <p>MimeType: {file.mimeType}</p>
        <p>Description: {file.description}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
        <Button onClick={confirmDelete}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
};
