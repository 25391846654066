import { TablePagination } from "@mui/material";
import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { searchQueryAtom, searchSelector } from "../../atoms/SearchSelector";
import { useAuthentication } from "../../utils/useAuthentication";
import "./SearchList.scss";

interface Props {
  rowsPerPageOptions: number[];
  count?: number;
}

export const SearchListPagination = (props: Props) => {
  const [query, setQuery] = useRecoilState(searchQueryAtom);
  // const { token } = useAuthentication();
  // const search = useRecoilValue(searchSelector(token));

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const rows = +e.target.value;
    setQuery({ ...query, rows: rows });
  };

  const handlePageChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    p: number
  ) => {
    const start = (query.rows ?? 10) * p;
    setQuery({ ...query, start: start });
  };

  const page = Number.parseInt(
    ((query.start ?? 0) / (query.rows ?? props.rowsPerPageOptions[0])).toFixed(
      0
    )
  );

  return (
    <TablePagination
      className="search-list-pagination"
      component="div"
      rowsPerPage={query.rows ?? props.rowsPerPageOptions[0]}
      rowsPerPageOptions={props.rowsPerPageOptions}
      onRowsPerPageChange={handleRowsPerPageChange}
      page={page}
      count={props.count ?? 0}
      onPageChange={handlePageChange}
    />
  );
};
