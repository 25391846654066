import { Avatar, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useAuthentication } from "../../utils/useAuthentication";
import "./LoginButton.scss";

interface Props {}

export const LoginButton = (props: Props) => {
  const { isAuthenticated, isLoading, signInWithRedirect, signOut, userData } =
    useAuthentication();
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  if (isLoading) return null;

  const renderButton = () => {
    if (isAuthenticated) {
      return (
        <div>
          <Tooltip title="Open settings">
            <IconButton
              onClick={(e) => {
                setMenuAnchor(e.currentTarget);
              }}
            >
              <Avatar
                alt={userData?.profile.preferred_username}
                src="broken.jpg"
              />
            </IconButton>
          </Tooltip>
          <Menu
            open={Boolean(menuAnchor)}
            anchorEl={menuAnchor}
            onClose={() => setMenuAnchor(null)}
          >
            {/* <MenuItem>View Profile</MenuItem> */}
            <MenuItem onClick={signOut}>Log Out</MenuItem>
          </Menu>
        </div>
      );
    } else
      return (
        <button className="logout" onClick={signInWithRedirect}>
          Log In
        </button>
      );
  };

  return <div className="login-btn">{renderButton()}</div>;
};
