import { CircularProgress } from "@mui/material";
import React from "react";
import "./Loaders.scss";

interface Props {}

export const CenterLoader = (props: Props) => {
  return (
    <div className="center-loader">
      <CircularProgress className="loader" />
    </div>
  );
};
