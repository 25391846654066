"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * BetterSoundboard.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchResultDtoToJSON = exports.SearchResultDtoFromJSONTyped = exports.SearchResultDtoFromJSON = exports.instanceOfSearchResultDto = void 0;
var AudioFileSolrDocument_1 = require("./AudioFileSolrDocument");
/**
 * Check if a given object implements the SearchResultDto interface.
 */
function instanceOfSearchResultDto(value) {
    var isInstance = true;
    isInstance = isInstance && "totalCount" in value;
    isInstance = isInstance && "documents" in value;
    return isInstance;
}
exports.instanceOfSearchResultDto = instanceOfSearchResultDto;
function SearchResultDtoFromJSON(json) {
    return SearchResultDtoFromJSONTyped(json, false);
}
exports.SearchResultDtoFromJSON = SearchResultDtoFromJSON;
function SearchResultDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totalCount': json['totalCount'],
        'documents': (json['documents'].map(AudioFileSolrDocument_1.AudioFileSolrDocumentFromJSON)),
    };
}
exports.SearchResultDtoFromJSONTyped = SearchResultDtoFromJSONTyped;
function SearchResultDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totalCount': value.totalCount,
        'documents': (value.documents.map(AudioFileSolrDocument_1.AudioFileSolrDocumentToJSON)),
    };
}
exports.SearchResultDtoToJSON = SearchResultDtoToJSON;
