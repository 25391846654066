import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue
} from "recoil";
import { FilesApi, UpdateFileDto } from "soundboard-api";
import {
  soundFileSelector,
  soundFilesSelector
} from "../../atoms/SoundFileSelector";
import { useApi } from "../../utils/useApi";
import { useAuthentication } from "../../utils/useAuthentication";
import "./EditSoundDialog.scss";
import { errorAtom } from "../../atoms/ErrorAtom";

interface Props {}

export const EditSoundDialog = (props: Props) => {
  const { fileId } = useParams();
  const { token } = useAuthentication();
  const navigate = useNavigate();
  const refresh = useRecoilRefresher_UNSTABLE(soundFilesSelector(token));
  const api = useApi(FilesApi, token);
  const [error, setError] = useRecoilState(errorAtom);

  const file = useRecoilValue(
    soundFileSelector({ token: token, fileId: fileId })
  );
  const { register, handleSubmit, formState } = useForm<UpdateFileDto>({
    reValidateMode: "onBlur",
    defaultValues: {
      name: file.name,
      description: file.description
    }
  });

  const handleFormSubmitted = async (data: UpdateFileDto) => {
    try {
      await api.apiFilesFileIdPut({
        fileId: file.id,
        updateFileDto: { name: data.name, description: data.description }
      });
      refresh();
      handleClose();
    } catch (e) {
      setError((e as string).toString());
    }
  };

  const handleClose = () => {
    navigate("/Sounds");
  };

  return (
    <Dialog open={Boolean(fileId)} onClose={handleClose}>
      <form
        onSubmit={handleSubmit((data) => handleFormSubmitted(data))}
        className="edit-sound-dialog"
      >
        <DialogTitle>Editing {file.name}</DialogTitle>
        <DialogContent className="content">
          <div className="input">
            <label>ID</label>
            <TextField disabled value={file.id} onChange={() => {}} />
          </div>
          <div className="input">
            <label>Name</label>
            <TextField
              error={Boolean(formState.errors.name)}
              {...register("name", { required: true })}
            />
          </div>
          <div className="input">
            <label>Extension</label>
            <TextField disabled value={file.extension} onChange={() => {}} />
          </div>
          <div className="input">
            <label>Mime Type</label>
            <TextField disabled value={file.mimeType} onChange={() => {}} />
          </div>
          <div className="input">
            <label>Blob ID</label>
            <TextField disabled value={file.blobId} onChange={() => {}} />
          </div>
          <div className="input">
            <label>Description</label>
            <TextField multiline rows={3} {...register("description")} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Confirm</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
