import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  BrowserRouter,
  createBrowserRouter,
  redirect,
  RouterProvider
} from "react-router-dom";
import { AuthProvider } from "oidc-react";
import { authConfig } from "./auth/AuthConfig";
import { LoginState } from "./interfaces/LoginState";
import { routes } from "./Routes";
import { RecoilRoot } from "recoil";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const handleRedirect = (loginState: LoginState | null) => {
  const url = loginState?.returnUrl ?? window.location.origin;
  window.location.href = url;
  // redirect(url);
};
const router = createBrowserRouter(routes);
root.render(
  <AuthProvider
    {...authConfig}
    onSignIn={(user) => {
      handleRedirect(user?.state as LoginState | null);
    }}
    onSignOut={(options) => {
      window.location.href = window.location.origin;
    }}
    autoSignIn={false}
    automaticSilentRenew={false}
  >
    <React.StrictMode>
      <RecoilRoot>
        <RouterProvider router={router} />
      </RecoilRoot>
    </React.StrictMode>
  </AuthProvider>
);
