import { useMemo } from "react";
import {
  BaseAPI,
  Configuration,
  ConfigurationParameters
} from "soundboard-api";
import { CreateApi } from "./CreateApi";

export function useApi<T extends BaseAPI>(
  type: new (configuration?: Configuration | undefined) => T,
  token?: string
): T {
  const api = useMemo(() => {
    const api = CreateApi(type, token);
    return api;
  }, [token, type]);

  return api;
}
