import React, { ChangeEvent, useEffect, useState } from "react";
import VolumeHighIcon from "@mui/icons-material/VolumeUp";
import VolumeMidIcon from "@mui/icons-material/VolumeDown";
import VolumeMuteIcon from "@mui/icons-material/VolumeOff";
import VolumeLowIcon from "@mui/icons-material/VolumeMute";
import "./VolumeSlider.scss";

interface Props {
  audioRef: React.RefObject<HTMLAudioElement>;
}

export const VolumeSlider = (props: Props) => {
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);

  const icon = muted ? (
    <VolumeMuteIcon />
  ) : volume === 0 ? (
    <VolumeLowIcon style={{ left: "-6px" }} />
  ) : volume > 0.5 ? (
    <VolumeHighIcon />
  ) : (
    <VolumeMidIcon style={{ left: "-3px" }} />
  );

  const handleVolumeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVolume(e.currentTarget.valueAsNumber);
  };

  useEffect(() => {
    if (props.audioRef.current) {
      props.audioRef.current.volume = volume;
    }
  }, [props.audioRef, volume]);

  const width = volume * 100;

  return (
    <div className="volume-slider">
      <div className="mute-btn" onClick={() => setMuted(!muted)}>
        {icon}
      </div>
      <div className="input-container">
        <div className="slider" style={{ width: `${width}%` }} />
        <input
          className="seeker-input"
          onChange={handleVolumeChange}
          max={1}
          min={0}
          step={0.01}
          type={"range"}
        />
      </div>
    </div>
  );
};
